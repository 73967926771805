import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { single_experience_tile_types } from '../../constants';
import {
  arrayTranslationFields,
  translationFieldConstants,
} from '../edit/utils/translationFieldConstants';
import { requiresContentTab } from './constants';

/**
 * @description Create a flatten object without nested properties
 * @param {currentNode} - The object to be flattened
 * @param {target} - Object to store the new flattened object
 * @param {flattenedKey} - optional - current key that is being flatten
 * @returns {{[name: string]: any}} - An flattened object representing the initial objects
 * Example -
 * Input - {description: 'Description', question: {label: 'Question 1', id: 1}}
 * Output - {description: 'Description', question.label: 'Question 1', question.id: 1}
 */
export const flattenObject = (currentNode, target = {}, flattenedKey) => {
  for (const [key, value] of Object.entries(currentNode)) {
    let newKey = key;
    if (flattenedKey !== undefined) {
      newKey = flattenedKey + '.' + key;
    }
    if (_.isObject(value)) {
      // if the current value is an object, call recursively to this method
      flattenObject(value, target, newKey);
    } else {
      target[newKey] = value;
    }
  }
  return target;
};

/**
 * It takes a field name and a schema and returns whether or not that field is disabled
 * @param fieldName - The name of the field you want to check
 * @param schema - The schema of the form it could be an array or an array of arrays
 * @returns {boolean} if the found field is disabled.
 */
const getFieldStatus = (fieldName, schema) => {
  for (const inputs of _.flattenDeep(schema)) {
    const foundInput = inputs.input?.find((input) => input.name === fieldName);
    if (foundInput) {
      return foundInput.disabled;
    }
  }
  return false;
};

/**
 * @description Check if object from forms has changes
 * @param {Object} values
 * @param {Object} formProps
 * @param {Object} previousValues
 * @param {String} context
 * @returns {Object} - object contains contentHasChanged and isEditing boolean values
 */
export const curiousPandaChangeHandler = (
  values,
  formProps,
  previousValues,
  context,
) => {
  const { form, dirty, schema } = formProps;
  const flattenedPreviousValues = flattenObject(previousValues);
  const flattenedValues = flattenObject(values);
  let changed = { isEditing: false, contentHasChanged: false };
  if (dirty) {
    for (const [k, v] of Object.entries(flattenedValues)) {
      const previousValue =
        typeof flattenedPreviousValues[k] === 'number'
          ? String(flattenedPreviousValues[k])
          : flattenedPreviousValues[k];
      const currentValue = typeof v === 'number' ? String(v) : v;
      // avoid check disabled fields since froala changes the content when it's disabled to 'example' and when is active is '<p>Active</p>'
      const isDisabled = getFieldStatus(k.split('.').slice(-1)[0], schema);
      if (
        previousValue !== undefined &&
        previousValue !== currentValue &&
        !isDisabled
      ) {
        // Used to disable adding form components, it can be expanded to different
        // forms, but the current need is only for 'context_edit' (SPP-340)
        // Note: this will work better with hooks instead of lifecycle methods, if this
        // component is transitioned into a functional component.
        const isEditing = form === context;
        changed = { isEditing, contentHasChanged: true };
        break;
      }
    }
  }

  return changed;
};

export const displayErrors = (errors = []) =>
  errors.forEach((error) => toastr.error(error));

export const displayWarnings = (warnings = []) =>
  warnings.forEach((warn) => toastr.warning(warn));

// TODO: The util function will be finalized and fixed with SPP-1338, this
// applies to 'formatInitialTileSettings' and 'formatTilePayload'. Currently
// set up to run the best with the education tile.
/**
 * @description Modifies payload from v3 endpoints to match form structures
 * @param {Object} payload
 * @param {String} type
 * @param {String} experience
 * @returns
 */
export const formatInitialTileSettings = (payload, type, experience) => {
  if (
    (!single_experience_tile_types.includes(type) ||
      requiresContentTab.includes(type)) &&
    experience
  ) {
    return {
      content: {
        experiences: {
          [experience]: { ...payload },
        },
      },
    };
  } else {
    return {
      content: {
        ...payload,
      },
    };
  }
};

/**
 * @description Modifies form structure of payload back to what v3 endpoint expects
 * @param {Object} payload
 * @param {String} experience
 * @returns {object}
 */
export const formatTilePayload = (payload, experience) => {
  let formattingPayload = {};
  const {
    content: { experiences, ...rest },
  } = payload;
  if (experiences && experiences?.[experience]) {
    formattingPayload = experiences[experience];
  }
  return { ...rest, ...formattingPayload };
};

/**
 * @description transform and merge values on a single object
 * @param {Object} primaryContent: value of the primary language response
 * @param {Object} secondaryContent: value of the secondary language response
 *  @param {String} currentLocale: value of the selected locale on top bar
 *  @return {Object}
 */
export const transformTranslatedFields = (
  primaryContent,
  secondaryContent,
  currentLocale,
) => {
  //get the keys to be translate
  const experiencesList = Object.keys(primaryContent);
  const modifiedExperiences = experiencesList.reduce((acc, experience) => {
    //check if the key is on the list to translatable keys
    if (translationFieldConstants.includes(experience)) {
      //return values with primary and secondary lang (including the rename `key_currentLocale`)
      return {
        ...acc,
        [experience]: primaryContent[experience],
        [`${experience}_${currentLocale}`]: secondaryContent[experience],
      };
    } else if (arrayTranslationFields.hasOwnProperty(experience)) {
      const mergedValues = primaryContent[experience].reduce((acc, field) => {
        const findSecondaryField = secondaryContent[experience].find(
          (sec) => sec.identifier === field.identifier,
        );

        if (findSecondaryField) {
          const secondaryKeys = Object.keys(findSecondaryField);

          const newSecondaryValues = secondaryKeys.reduce(
            (acc, key) => ({
              ...acc,
              [`${key}_${currentLocale}`]: findSecondaryField[key],
            }),
            {},
          );

          return [...acc, { ...field, ...newSecondaryValues }];
        }

        return [...acc, field];
      }, []);
      return { ...acc, [experience]: mergedValues };
    } else {
      // Omit others fields
      return {
        ...acc,
        [experience]: primaryContent[experience],
      };
    }
  }, {});
  return modifiedExperiences;
};

/**
 * @description validate if we have a multi experience and call {transformTranslatedFields}
 * to be translated
 * @param {Object} primaryContent: value of the primary language response
 * @param {Object} secondaryContent: value of the secondary language response
 *  @param {String} currentLocale: value of the selected locale on top bar
 *  @return {Object}
 */
export const mergeTranslationValues = (
  secondaryContent,
  primaryContent,
  currentLocale,
  activeExperience = 'default',
  type = null,
) => {
  //Review if we have multi-experiences
  if (primaryContent?.content?.experiences) {
    const modifiedExperiences = transformTranslatedFields(
      primaryContent.content.experiences[activeExperience].settings,
      secondaryContent.content.experiences[activeExperience].settings,
      currentLocale,
    );

    let connectEHRSettings = {};
    // TODO: We may need to update this for the new ConnectEHRData
    if (type === 'connect_ehr') {
      for (const [key, value] of Object.entries(
        primaryContent.content.experiences[activeExperience].settings,
      )) {
        connectEHRSettings[key] = transformTranslatedFields(
          value,
          secondaryContent.content.experiences[activeExperience].settings[key],
          currentLocale,
        );
      }
    }

    return {
      ...primaryContent,
      content: {
        experiences: {
          [activeExperience]: {
            ...primaryContent.content.experiences[activeExperience],
            settings: { ...modifiedExperiences, ...connectEHRSettings },
          },
        },
      },
    };
  }
  //If we have a single experience we translate only the content
  else {
    primaryContent.content = transformTranslatedFields(
      primaryContent?.content,
      secondaryContent?.content,
      currentLocale,
    );
  }

  return primaryContent;
};
