import * as C from '../common';

export default data => {
  data.content = {
    ...data.content,
    description: `In this step you will find and connect health care provider records for the study. You'll learn more about how we are using this data.`,
    action_title: `Connect ElectronicHealth Records`,
  };

  return C.common_outgoing(data);
};
