import { FC, useMemo } from 'react';
import styled from 'styled-components';
import DefaultLanguageForm from './DefaultLanguageForm';
import GraphLanguageTable from './GraphLanguageTable';
import { Study, Locale } from 'src/redux/graph';
import { useSelector } from 'react-redux';
import CreateLanguageForm from './CreateLanguageForm';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  padding: 2rem;
  background-color: white;
  height: auto;
  font-family: Avenir;
`;

const Title = styled.h1`
  color: #1a1a1a;
  font-family: 'Source Serif Pro';
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

const Heading = styled.h3`
  color: #1a1a1a;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

const Description = styled.p`
  color: #5e5e5e;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const Translations: FC = () => {
  const languages: Locale = useSelector(
    (state: Locale) => state.locale.languages,
  );
  const graph: Study = useSelector((state: Study) => state.graph);

  const languageOptions = useMemo(
    () =>
      Object.keys(languages).map((lang) => ({
        label: languages[lang].label,
        value: lang,
      })),
    [languages],
  );

  return (
    <Container>
      <Title>Internationalization</Title>
      <Heading>Choose locale(s)</Heading>
      <Description>
        Please choose the locale(s) being used in this project. the fallback
        language when there is no matching locale.
      </Description>
      <CreateLanguageForm
        graphId={graph.id}
        languageOptions={languageOptions}
      />
      <GraphLanguageTable />
      <Heading>Default Language</Heading>
      <Description>
        This is the language your project is configured with. It is also used as
        the fallback language when there is no matching locale.
      </Description>
      <Description>
        Note: We recommend you finish configuring the project in the default
        language before adding translations. Otherwise, you will need to update
        the translations after making any textual changes to the project.
      </Description>
      <DefaultLanguageForm graph={graph} languageOptions={languageOptions} />
    </Container>
  );
};

export default Translations;
