import { FC, PropsWithChildren, useState } from 'react';
import DropdownMenu from 'react-dd-menu';
import styled from 'styled-components';

const StyledDropdownButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
`;

const StyleDropdownMenu = styled(DropdownMenu)`
  .dd-menu-items {
    max-width: 160px;
    z-index: 30;
  }

  ul.dd-items-right {
    padding: 8px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);

    a {
      display: flex;
      padding: 8px 12px;
      color: #162d6d;
      border-radius: 5px;
      font-weight: 400;

      &:hover {
        background-color: #e3e3e3;
      }
    }
  }
`;

const TableDropdown: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const options = {
    isOpen,
    align: 'right',
    size: 'md',
    closeOnInsideClick: false,
    close: () => setIsOpen(false),
    toggle: (
      <StyledDropdownButton onClick={handleDropdownClick} aria-label="actions">
        ...
      </StyledDropdownButton>
    ),
  };

  return <StyleDropdownMenu {...options}>{children}</StyleDropdownMenu>;
};

export default TableDropdown;
