import { AnyAction, Dispatch } from 'redux';
import types from '../constants';
import { userconst } from '../reducers/contants';
import { type Authorization, User } from '../redux/graph';
import { apiGraph, userAuthorizedActions } from 'src/api';

type LocalStorageUserData = Pick<User, 'token' | 'username' | 'authorization'>;

export const getUserCredentials = () => {
  return {
    username: localStorage.getItem(userconst.username),
    token: localStorage.getItem(userconst.token),
  };
};

export const getAuthorization = async (token, username) => {
  const { data: authorization } = await userAuthorizedActions({
    token,
    userEmail: username,
  });
  return authorization;
};

export const refreshStudies = async (dispatch) => {
  const graph = await apiGraph.get();
  dispatch(setStudies(graph));
  dispatch(getStudies());
};

export const getStudies = () => ({ type: types.user.get_studies });
export const setStudies = (payload: any) => ({
  type: types.user.set_studies,
  payload,
});

export const setUserAuthorizations = (userAuthorizations: Authorization) => {
  localStorage.setItem(
    userconst.authorization,
    JSON.stringify(userAuthorizations),
  );
};

export const setLocalstorage = (userData: LocalStorageUserData) => {
  localStorage.setItem(userconst.token, userData.token);
  localStorage.setItem(userconst.username, userData.username);
  setUserAuthorizations(userData.authorization);
};

export const unsetLocalStorage = () => {
  localStorage.removeItem(userconst.token);
  localStorage.removeItem(userconst.username);
  localStorage.removeItem(userconst.authorization);
};

export const setCookie = (cname: string, cvalue: string, exdays?: string) => {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie =
    cname +
    '=' +
    cvalue +
    ';' +
    expires +
    `;domain=${
      window.env.REACT_APP_TRIAGE_API_DOMAIN || window.location.hostname
    }` +
    ';path=/';
};

export const loginUser = (payload: User) => ({
  type: types.user.log_in,
  payload,
});
export const loggedIn = (payload: Partial<User>) => ({
  type: types.user.logged_in,
  payload,
});
export const logoutUser = () => ({ type: types.user.log_out });
export const assignAuthorization = (payload: User) => ({
  type: types.user.assign_authorization,
  payload,
});

export const logout = () => (dispatch: Dispatch<AnyAction>) => {
  // need to add loader
  unsetLocalStorage();
  dispatch(logoutUser());
};

export const refreshStudiesAndActions = async (
  dispatch: Dispatch<AnyAction>,
) => {
  const { username, token } = getUserCredentials();
  const authorization = await getAuthorization(token, username);
  const payload = { token, username, authorization } as User;
  loginUser({
    token: token,
    username: username,
    authorization: authorization,
  } as User);
  setLocalstorage(payload);
  dispatch(assignAuthorization(payload));
  await refreshStudies(dispatch);
};
