import { Study } from 'src/redux/graph';
import { triageV3 } from './triage';

export type GraphLocale = {
  name: string;
  percent_translated: number | null;
  primary: boolean;
  created_at: string;
  updated_at: string;
};

export type UploadedLocale = {
  location: string;
  checksum: string;
  original: string;
  translated: string | null;
};

type GraphDefaultLocale = {
  name: string;
};

type GraphLocalePayload = {
  graphId: string;
  data: GraphDefaultLocale;
};

export const translationsService = {
  getAll: (graphId: number) => {
    return triageV3
      .get<GraphLocale[]>(`/graphs/${graphId}/locales`)
      .then(({ data }) => data);
  },
  create: ({ graphId, data }: GraphLocalePayload) => {
    return triageV3
      .post<GraphLocale>(`graphs/${graphId}/locales`, data)
      .then(({ data }) => data);
  },
  updateDefaultLocale: ({ graphId, data }: GraphLocalePayload) => {
    return triageV3
      .put<Study>(`graphs/${graphId}/primary_locale`, data)
      .then(({ data }) => data);
  },
  deleteLocale: ({
    graphId,
    localeId,
  }: {
    graphId: number;
    localeId: string;
  }) => {
    return triageV3
      .delete<GraphLocale>(`/graphs/${graphId}/locales/${localeId}`)
      .then(({ data }) => data);
  },
  getLocaleTranslations: ({
    graphId,
    locale,
  }: {
    graphId: number;
    locale: string;
  }) => {
    return triageV3
      .get<UploadedLocale[]>(
        `/graphs/${graphId}/locales/${locale}/translations`,
      )
      .then(({ data }) => data);
  },
  uploadLocales: ({
    graphId,
    locale,
    data,
  }: {
    graphId: number;
    locale: string;
    data: UploadedLocale[];
  }) => {
    return triageV3
      .put<UploadedLocale[]>(
        `/graphs/${graphId}/locales/${locale}/translations`,
        data,
      )
      .then(({ data }) => data);
  },
};
