import { useCallback, useMemo, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import transform_incoming from './incoming';

const text = {
  unhandled_error:
    'Something went wrong submitting your form. Make sure you have included all required * fields.',
};
/**
 * Format the tile data to pass back up to the default change handler
 * @param {Object} intialValues initial values of the form
 * @param {Object} settings settings form local state
 * @param {String} experience_key experience key for the tile
 * @param {Object} content content form local state
 * @param {Array} connections connections form local state
 */
function formatTileData({
  initialValues,
  settings,
  experience_key,
  content,
  connections,
}) {
  const data = {
    ...initialValues,
    content: {
      ...settings,
      experiences: {
        [experience_key]: {
          ...content,
        },
      },
      available_apps: connections,
    },
  };
  return data;
}
/**
 * Top level check to validate main content form.
 * Used to disable the submit button
 * @param {object} content
 * @returns
 */
function hasInvalidContent(content) {
  if (content?.title) {
    return false;
  }
  return true;
}
/**
 * Top level check to validate connections form.
 * Used to disable the submit button
 * @param {object} content
 * @returns
 */
function hasInvalidConnections(connections) {
  return connections.some(
    (connection) =>
      !(connection && connection.id && connection.title),
  );
}

function isInvalidString(value) {
  return value === undefined || !String(value) || String(value).length === 0;
}

function hasInvalidCommonSettings(settings) {
  return (
    isInvalidString(settings.collection_length) ||
    isInvalidString(settings.collection_start)
  );
}

export default function useConnectAppForm({
  experience_key,
  initialValues,
  onSubmit,
  curiousPandaChangeHandler,
}) {
  const [content, setContent] = useState({});
  const [connections, setConnections] = useState([]);
  const [settings, setSettings] = useState({});

  const handleFormChange = useCallback(
    (type, values, dispatch, props, previousValues) => {
      if (type === 'content') {
        setContent(values?.content?.experiences?.[experience_key]);
      }
      if (type === 'connection') {
        const con = values?.map((item) => item.connection);
        setConnections(con);
      }
      if (type === 'settings') {
        if (values && values.content) {
          setSettings((prevSettings) => ({
            ...prevSettings,
            ...values.content,
          }));
        }
      }
      const data = formatTileData({
        initialValues,
        connections,
        settings,
        content,
        experience_key,
      });
      if (props) {
        curiousPandaChangeHandler(data, dispatch, props, initialValues);
      }
    },
    [
      setContent,
      setConnections,
      setSettings,
      experience_key,
      curiousPandaChangeHandler,
      initialValues,
      content,
      settings,
      connections,
    ],
  );

  const handleSubmit = useCallback(() => {
    if (content && connections && settings) {
      const data = formatTileData({
        initialValues,
        connections,
        content,
        settings,
        experience_key,
      });
      onSubmit(data);
    } else {
      toastr.error(text.unhandled_error);
    }
  }, [content, connections, settings, experience_key, initialValues, onSubmit]);

  const isDisabled = useMemo(() => {
    let disabled = false;
    if (hasInvalidConnections(connections)) {
      disabled = true;
    }
    if (hasInvalidContent(content)) {
      disabled = true;
    }
    if (hasInvalidCommonSettings(settings)) {
      disabled = true;
    }
    return disabled;
  }, [connections, content, settings]);

  const transformedInitialValues = useMemo(() => {
    return transform_incoming(initialValues);
  }, [initialValues]);

  return {
    transformedInitialValues,
    isDisabled,
    handleSubmit,
    handleFormChange,
    content,
    connections,
    settings,
  };
}
