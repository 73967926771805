import { default_experience_key } from '../../../../constants';
import * as C from '../common';
import { getInputsForExperience } from '../utils';

/**
 * @description The tile level settings part of the schema for the connect_ehr_data
 * @param {String} experience
 * @param {Array} displayGroupNames
 * @param {Boolean} masonryEnabled - flag for new components
 * @returns {Object}
 */
export const ConnectEHRDataTileSettings = (
  experience,
  displayGroupNames,
  masonryEnabled = false,
) => {
  const label = masonryEnabled ? 'Settings' : 'More Settings';
  return {
    label,
    name: 'content',
    input: getInputsForExperience({
      experience,
      mandatoryInputs: [
        C.experienceTileTitle(masonryEnabled),
        C.experienceTileDescription(masonryEnabled),
        C.optional,
        C.display_group_name(displayGroupNames),
      ],
      optionalInputs: [
        ...C.dependencyCriteria({
          emitEvent: true,
          visibility: true,
          cancellation: true,
        }),
      ],
    }),
  };
};

export default ({
  displayGroupNames,
  experience = default_experience_key,
  dataForTile,
}) => {
  const {
    connect_ehr_data: { masonryEnabled = false },
  } = dataForTile;

  const schema = [
    {
      label: 'Settings',
      name: 'content',
      input: [
        C.experienceExternalTitle(masonryEnabled),
        C.experienceExternalDescription(masonryEnabled),
        {
          label: 'Electronic Health Record Type',
          name: 'record_type',
          interface: 'select',
          disabled: true,
          possible_answers: ['1Up Health'],
          initialValue: '1Up Health',
        },
        {
          label: 'Intro Headline',
          name: 'intro_headline',
          interface: 'textinput',
          required: true,
          inputStyle: 'sm_minimal',
        },
        {
          label: 'Intro Description',
          name: 'intro_desc',
          interface: 'textarea',
          inputStyle: 'sm_minimal',
          required: true,
        },
        {
          label: 'Intro Tip',
          name: 'intro_tip',
          interface: 'textinput',
          inputStyle: 'sm_minimal',
          required: true,
        },
        {
          label: 'Privacy URL',
          name: 'privacy_url',
          interface: 'textinput',
          inputStyle: 'sm_minimal',
          required: true,
          default: 'https://www.myachievement.com/privacy'
        },
        {
          label: 'Complete Button Label',
          name: 'complete_button_label',
          interface: 'textinput',
          inputStyle: 'sm_minimal',
          required: true,
        },
      ],
    },
  ];

  if (!masonryEnabled) {
    schema.push(
      ConnectEHRDataTileSettings(
        experience,
        displayGroupNames,
      ),
    );
  }

  return schema;
};
