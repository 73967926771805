import * as C from '../common';
import _ from 'lodash';

export const initVal = {
  title: `Connect Health Records`,
  description: `In this step you will find and connect health care provider records for the study. You'll learn more about how we are using this data.`,
  button_label: `Connect Health Records`,
  intro_headline: `Find and connect your healthcare providers`,
  intro_desc: `We need a connection to your health care provider to access certain data about your care history.`,
  intro_tip: `Your login credentials for each health care provider you want to connect with.`,
  complete_button_label: `I'm done connecting my records`,
  privacy_url: `https://www.myachievement.com/privacy`,
};

export default (data) => {
  const incoming = data.content;
  _.each(initVal, (val, key) => {
    if (_.isUndefined(incoming[key])) {
      incoming[key] = val;
    }
  });

  data = {
    ...data,
    ...incoming,
  };

  return C.common_incoming(data);
};
