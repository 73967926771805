import { FC } from 'react';
import { useUploadGraphLanguages } from './useTranslations';
import styled from 'styled-components';
import { variants } from '@evidation/ui/lib/Button';
import { csvToJson } from './utils';

interface UploadLanguagesButtonProps {
  graphId: number;
  locale: string;
  id: string;
}

const UploadLocaleButton = styled.label`
  > input {
    display: none;
  }
  box-sizing: border-box;
  border: 0;
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px 8px;
  width: 100%;
  font-size: 14px;
  ${variants.secondary}
  background-color: #fff;
`;

const UploadLanguagesButton: FC<UploadLanguagesButtonProps> = ({
  graphId,
  locale,
  id,
}) => {
  const { mutate: onUploadGraphLanguages } = useUploadGraphLanguages();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const csvAsString = e.target?.result as string;
        const data = csvToJson(csvAsString);
        onUploadGraphLanguages({ graphId, locale, data });
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };
    reader.readAsText(file);
  };

  return (
    <UploadLocaleButton htmlFor={id}>
      <input type="file" onChange={handleFileChange} accept=".csv" id={id} />
      <span>Import</span>
    </UploadLocaleButton>
  );
};

export default UploadLanguagesButton;
