"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateFutureDate = exports.validateFormat = exports.validateDateBounds = exports.validateDate = exports["default"] = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.date.to-string.js");
var _dateFns = require("date-fns");
var _i18n = require("../../i18n");
var _utils = require("../../utils");
/*
  field level validations
*/
var _default = exports["default"] = function _default(_ref) {
  var dob_format = _ref.dob_format,
    minimum = _ref.minimum,
    maximum = _ref.maximum,
    value = _ref.value;
  return [validateDateBounds(minimum, maximum, dob_format), validateDate(dob_format), validateFormat(dob_format), validateFutureFormatDate(dob_format)];
};
var getErrorMessageKey = function getErrorMessageKey(minDate, maxDate) {
  var prefix = 'validations.dob';
  var keysSet = {
    min: 'errorMessageMin',
    max: 'errorMessageMax',
    minAndMax: 'errorMessageMinAndMax'
  };
  if (!(0, _utils.isEmpty)(minDate) && !(0, _utils.isEmpty)(maxDate)) {
    return "".concat(prefix, ".").concat(keysSet.minAndMax);
  } else if ((0, _utils.isEmpty)(minDate) & !(0, _utils.isEmpty)(maxDate)) {
    return "".concat(prefix, ".").concat(keysSet.max);
  } else if (!(0, _utils.isEmpty)(minDate) & (0, _utils.isEmpty)(maxDate)) {
    return "".concat(prefix, ".").concat(keysSet.min);
  }
};
var validateDateBounds = exports.validateDateBounds = function validateDateBounds(minDate, maxDate, format) {
  return function (inputDate) {
    // Parse the input date
    var parsedInputDate = (0, _dateFns.parse)(inputDate, transformFormat(format), new Date());

    // Parse the min date (if provided)
    var parsedMinDate = minDate ? (0, _dateFns.parse)(minDate, 'yyyy-MM-dd', new Date()) : null;

    // Parse the max date (if provided)
    var parsedMaxDate = maxDate ? (0, _dateFns.parse)(maxDate, 'yyyy-MM-dd', new Date()) : null;

    // Validate against min date
    if (parsedMinDate && (0, _dateFns.isBefore)(parsedInputDate, parsedMinDate) && !(0, _dateFns.isEqual)(parsedInputDate, parsedMinDate)) {
      return (0, _i18n.globalTranslate)(getErrorMessageKey(minDate, maxDate), {
        minDate: minDate,
        maxDate: maxDate
      });
    }

    // Validate against max date
    if (parsedMaxDate && (0, _dateFns.isAfter)(parsedInputDate, parsedMaxDate) && !(0, _dateFns.isEqual)(parsedInputDate, parsedMaxDate)) {
      return (0, _i18n.globalTranslate)(getErrorMessageKey(minDate, maxDate), {
        minDate: minDate,
        maxDate: maxDate
      });
    }

    // If all checks pass, the date is within bounds
    return undefined;
  };
};
var validateDate = exports.validateDate = function validateDate() {
  var format = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  return function (value) {
    if (value) {
      var parsed = format !== undefined ? (0, _dateFns.parse)(value, transformFormat(format), new Date()) : (0, _dateFns.parseISO)(value);
      return (0, _dateFns.isValid)(parsed) && (0, _dateFns.isDate)(parsed) ? undefined : (0, _i18n.globalTranslate)('validations.dob.invalidDate');
    }
  };
};
var transformFormat = function transformFormat(format) {
  switch (format) {
    case 'YYYY':
      return 'yyyy';
    case 'YYYY-MM':
      return 'yyyy-MM';
    case 'MM-DD-YYYY':
      return 'MM-dd-yyyy';
    case 'YYYY-MM-DD':
    default:
      return 'yyyy-MM-dd';
  }
};
var validateFormat = exports.validateFormat = function validateFormat(format) {
  return function (value) {
    if (value) {
      switch (format) {
        case 'YYYY':
          return /^[0-9]{4}$/.test(value) ? undefined : (0, _i18n.globalTranslate)('validations.dob.invalidDateFormat', {
            format: (0, _i18n.globalTranslate)('common.dateFormats.year')
          });
        case 'YYYY-MM':
          return /^[0-9]{4}-[0-9]{2}$/.test(value) ? undefined : (0, _i18n.globalTranslate)('validations.dob.invalidDateFormat', {
            format: (0, _i18n.globalTranslate)('common.dateFormats.yearMonth')
          });
        case 'MM-DD-YYYY':
          return /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/.test(value) ? undefined : (0, _i18n.globalTranslate)('validations.dob.invalidDateFormat', {
            format: (0, _i18n.globalTranslate)('common.dateFormats.monthYearDay')
          });
        case 'YYYY-MM-DD':
        default:
          return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value) ? undefined : (0, _i18n.globalTranslate)('validations.dob.invalidDateFormat', {
            format: (0, _i18n.globalTranslate)('common.dateFormats.yearMonthDay')
          });
      }
    }
  };
};
var validateFutureFormatDate = function validateFutureFormatDate() {
  var format = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  return function (value) {
    if (value) {
      var parsed = format !== undefined ? (0, _dateFns.parse)(value, transformFormat(format), new Date()) : (0, _dateFns.parseISO)(value);
      return (0, _dateFns.isFuture)(parsed) ? (0, _i18n.globalTranslate)('validations.dob.cannotBeFuture') : undefined;
    }
  };
};
var validateFutureDate = exports.validateFutureDate = function validateFutureDate(value) {
  if (value) {
    var parsed = (0, _dateFns.parseISO)(value);
    return (0, _dateFns.isFuture)(parsed) ? (0, _i18n.globalTranslate)('validations.dob.cannotBeFuture') : undefined;
  }
};