import { Button, Select } from '@evidation/ui';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCreateGraphLanguage } from './useTranslations';
import styled from 'styled-components';
import { type Option } from '@evidation/ui/lib/Select';
import Modal from '../modal/SimpleModal';

type TranslationsForm = {
  languages: string[];
};

interface CreateGraphLocaleFormProps {
  graphId: number;
  languageOptions: Option[];
}

const ModalContainer = styled.div`
  display: flex;
  padding: 3rem;
`;

const Form = styled.form`
  display: flex;
  width: 100%;
  gap: 1rem;

  > div {
    margin-bottom: 0;
  }

  [data-testid='t-select-languages'] {
    .select {
      padding: 0.5rem;
    }
  }
`;

const FormButton = styled(Button)`
  height: 40px;
  align-self: end;
`;

const AddLanguageSection = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const CreateLanguageForm: FC<CreateGraphLocaleFormProps> = ({
  graphId,
  languageOptions,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { mutate: onCreateGraphLocale } = useCreateGraphLanguage();

  const {
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm<TranslationsForm>({
    defaultValues: {
      languages: [''],
    },
  });

  const onSubmit = async (data: TranslationsForm) => {
    try {
      await Promise.all(
        data.languages.map((lang) =>
          onCreateGraphLocale({ graphId: `${graphId}`, data: { name: lang } }),
        ),
      );
    } catch (error) {
      console.error('Error in one of the mutations:', error);
    } finally {
      setValue('languages', ['']);
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  return (
    <AddLanguageSection>
      <Button
        onClick={() => setShowModal(true)}
        data-testid="i18n-btn"
        variant="primary"
        size="large"
      >
        Add Language
      </Button>
      {showModal && (
        <Modal
          showX
          modalTitle="Add Language(s)"
          onClose={onCloseModal}
          holderClassName="webhooks-modal"
        >
          <ModalContainer>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Select
                error={errors.languages ? 'This field is required' : undefined}
                options={languageOptions}
                multiple
                label="Languages"
                name="languages"
                style={{ flex: '1 1 80%' }}
                onChange={(options) => {
                  const values = options.map((option) => option.value);
                  setValue('languages', values, {
                    shouldValidate: true,
                  });
                }}
                placeholder="Select languages"
                value={watch('languages')}
              />
              <FormButton
                variant="primary"
                size="large"
                type="submit"
                data-testid="i18n-btn"
              >
                Add Language
              </FormButton>
            </Form>
          </ModalContainer>
        </Modal>
      )}
    </AddLanguageSection>
  );
};

export default CreateLanguageForm;
