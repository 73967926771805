import { UploadedLocale } from 'src/api/translations';

const cleanText = (text: string) => {
  const currentText = text;
  const charsToRemove = /^"|"$|[\r\n\t]/g;
  const textWithoutChars = currentText.replace(charsToRemove, '');
  return textWithoutChars;
};

export const transformToCSV = (data: UploadedLocale[], locale: string) => {
  const csvRows = [
    `Location,Checksum,Default Text,${locale} (Modify this column only)`,
  ];

  for (const item of data) {
    const translatedValue = item.translated ?? '';
    csvRows.push(
      `${item.location},${item.checksum},"${cleanText(
        item.original,
      )}","${cleanText(translatedValue)}"`,
    );
  }

  const csvContent = csvRows.join('\n');
  return csvContent;
};

export const csvToJson = (csvData: string): UploadedLocale[] => {
  const lines = csvData.split('\n');
  const header = lines[0].split(',');
  const data: UploadedLocale[] = [];

  for (let i = 1; i < lines.length; i++) {
    const row: string[] = [];
    let currentField = '';
    let inQuotes = false;

    for (let char of lines[i]) {
      if (char === '"') {
        inQuotes = !inQuotes;
      } else if (char === ',' && !inQuotes) {
        row.push(currentField);
        currentField = '';
      } else {
        currentField += char;
      }
    }

    row.push(currentField);

    if (row.length === header.length) {
      const location = row[0];
      const checksum = row[1];
      const original = cleanText(row[2]);
      const translated = cleanText(row[3]);

      data.push({
        location,
        checksum,
        original,
        translated: translated === '' ? null : translated,
      });
    }
  }

  return data;
};
