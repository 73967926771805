import * as C from '../common';
import { default_experience_key } from '../../../../constants';
import { getInputsForExperience } from '../utils';

/**
 * @description The tile level settings part of the schema for the experian_validator
 * @param {String} experience
 * @param {Array} displayGroupNames
 * @param {Boolean} masonryEnabled - flag for new components
 * @returns {Object}
 */
 export const ExperianValidatorTileSettings = (
  experience,
  displayGroupNames,
  masonryEnabled = false,
) => {
  return {
    label: 'Settings',
    name: 'content',
    input: getInputsForExperience({
      experience,
      mandatoryInputs: [
        C.experienceTileTitle(masonryEnabled),
        C.experienceTileDescription(masonryEnabled),
        C.display_group_name(displayGroupNames),
      ],
      optionalInputs: [
        ...C.dependencyCriteria({
          emitEvent: true,
          availabilty: true,
          visibility: true,
          cancellation: true,
          completion: true,
        }),
      ],
    }),
  };
};

export default ({
  arms,
  id,
  initialValues,
  nodeSlugs,
  experience = default_experience_key,
  displayGroupNames,
  dataForTile,
}) => {
  const {
    experian_validator: { masonryEnabled = false },
  } = dataForTile;

  const experienceName = masonryEnabled
    ? `content.experiences.${experience}.settings`
    : `content`;

  const schema = [
    {
      label: 'Content',
      name: experienceName,
      input: getInputsForExperience({
        experience,
        mandatoryInputs: [
          { ...C.experienceExternalTitle(masonryEnabled), required: true },
        ],
        optionalInputs: [
          C.tooltip,
        ]
      }),
    },
    {
      label: 'One-Time Password Messages',
      name: experienceName,
      input: getInputsForExperience({
        experience,
        mandatoryInputs: [
          {
            name: 'success_message',
            label: 'Success message',
            interface: 'textarea',
            required: true,
            inputStyle: `sm_minimal`,
          },
          {
            name: 'fail_message',
            label: 'Fail message',
            interface: 'textarea',
            required: true,
            inputStyle: `sm_minimal`,
          },
          {
            name: 'prompt_message',
            label: 'Prompt message',
            interface: 'textarea',
            required: true,
            inputStyle: `sm_minimal`,
          },
          {
            name: 'incorrect_message',
            label: 'Incorrect message',
            interface: 'textarea',
            required: true,
            inputStyle: `sm_minimal`,
          },
          {
            name: 'expired_message',
            label: 'Expired message',
            interface: 'textarea',
            required: true,
            inputStyle: `sm_minimal`,
          },
          {
            name: `max_retries`,
            label: `Max number of retries`,
            interface: `numberinput`,
            required: true,
            validate: [C.validateNumber, C.validateInt],
          },
        ],
        optionalInputs: [
          {
            ...C.enabled,
            name: 'skip_fake',
            label: 'Skip fake enrollments',
          },
        ],
      }),
    },
    {
      label: 'Identity Verification Intro Message',
      name: experienceName,
      input: getInputsForExperience({
        experience,
        mandatoryInputs: [],
        optionalInputs: [
          {
            name: 'intro_page_title',
            label: 'Title',
            interface: 'textarea',
            required: false,
            inputStyle: `sm_minimal`,
          },
          {
            name: 'intro_page_confirm_text',
            label: 'Body',
            interface: 'rte',
            required: false,
            inputStyle: `sm_minimal`,
          },
          {
            name: 'intro_page_confirm_button_text',
            label: 'Button Text (For Continue Button) ',
            interface: 'textinput',
            required: false,
            inputStyle: `sm_minimal`,
          },
          {
            name: 'intro_page_contact_button_text',
            label: 'Button Text (For Contact Us Modal)',
            interface: 'textinput',
            required: false,
            inputStyle: `sm_minimal`,
          },
        ],
      }),
    },
    {
      label: 'Identity Verification Success Message',
      name: experienceName,
      input: getInputsForExperience({
        experience,
        mandatoryInputs: [],
        optionalInputs: [
          {
            name: 'success_page_title',
            label: 'Title',
            interface: 'textarea',
            required: false,
            inputStyle: `sm_minimal`,
          },
          {
            name: 'success_page_confirm_text',
            label: 'Body',
            interface: 'rte',
            required: false,
            inputStyle: `sm_minimal`,
          },
          {
            name: 'success_page_button_text',
            label: 'Button Text (to return to dashboard)',
            interface: 'textinput',
            required: false,
            inputStyle: `sm_minimal`,
          },
        ],
      }),
    },
    {
      label: 'Identity Verification Failure Message',
      name: experienceName,
      input: getInputsForExperience({
        experience,
        mandatoryInputs: [],
        optionalInputs: [
          {
            name: 'failure_page_title',
            label: 'Title',
            interface: 'textarea',
            required: false,
            inputStyle: `sm_minimal`,
          },
          {
            name: 'failure_page_confirm_text',
            label: 'Body',
            interface: 'rte',
            required: false,
            inputStyle: `sm_minimal`,
          },
          {
            name: 'failure_page_confirm_button_text',
            label: 'Button Text (for Return to Dashboard)',
            interface: 'textinput',
            required: false,
            inputStyle: `sm_minimal`,
          },
          {
            name: 'failure_page_contact_button_text',
            label: 'Button Text (for contact us modal)',
            interface: 'textinput',
            required: false,
            inputStyle: `sm_minimal`,
          },
        ],
      }),
    },
  ];

  if (!masonryEnabled) {
    schema.push(ExperianValidatorTileSettings(experience, displayGroupNames));
  }

  return [schema];
};
