// TODO: This file will most likely be replaced by information from Triage in the future.
/**
 * @description Array with fields that can contain translations, this can be used to identify
 * fields in tiles that can be translated.
 */
export const translationFieldConstants = [
  'push_title',
  'push_body',
  'title',
  'description',
  'action_title',
  'tooltip',
  'error_message',
  'button_label',
  'find_desc',
  'find_title',
  'find_search',
  'intro_tip',
  'intro_headline',
  'intro_button_label',
  'intro_desc',
  'complete_button_label',
  'confirm_back',
  'confirm_body',
  'confirm_title',
  'confirm_submit',
  'connected_desc',
  'connected_title',
  'connected_headline',
  'connected_done_label',
  'connected_list_label',
  'connected_headline_desc',
  'connected_add_more',
  'contribution_title',
  'external_title',
  'completion_message',
  'hero_title',
  'hero_content',
  'hero_button',
  'eligibility_content',
  'faq_enroll_form_heading',
  'how_you_help_heading',
  'faq_heading',
  'fail_message',
  'prompt_message',
  'expired_message',
  'success_message',
  'incorrect_message',
  'mfa_phone_input_description',
  'mfa_phone_input_label',
  'mfa_token_input_description',
  'mfa_token_input_label',
  'label',
  'other_label',
  'sendgrid_template_id',
  'survey_url',
  'external_url',
  'subject',
];

/**
 * @description Sub-list of fields that have an interface type of array. This is should only
 * contain a key with an array. The array contains fields that should not be translatable.
 */
export const arrayTranslationFields = {
  sendgrid_personalizations: [],
  how_you_help_steps: [],
  faq_array: [],
  possible_answers: ['answer', 'additional_payload'],
  answer_labels: [],
  questions: [],
  display_groups: ['layout', 'enable_progress_tracker_panel'],
  sms_messages: [],
  body: [],
};

/**
 * @description List of translatable fields in the content section of the graph
 */
export const graphTranslationFields = [
  'keywords',
  'study_name',
  'study_summary',
  'dashboard_header',
  'opt_out_description',
  'study_closed_content',
  'connected_apps_header',
  'contact_modal_heading',
  'contact_modal_message',
  'dashboard_withdrawal_link',
  'enrollment_closed_content',
  'connected_apps_description',
  'dashboard_withdrawal_label',
  'dashboard_withdrawal_modal_header',
  'dashboard_withdrawal_modal_reason',
  'dashboard_withdrawal_modal_paragragh_1',
  'dashboard_withdrawal_modal_paragragh_2',
  'reconsent_modal_body',
  'reconsent_modal_title',
];
