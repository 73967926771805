import { useEffect, useState } from 'react';
import useLoad from './useLoad';
import useCra from './useCra';
import useVersionHistory from './useVersionHistory';
import { checkPermissions } from './permissions';
import {
  displaySecondaryMenu,
  generateMenuOptions,
  doNotDisplaySecondaryMenu,
} from './menuOptions';
import useExportImportLang from './useExportImportLang';
import useExportAuditTrail from './useExportAuditTrail';
import { userconst } from '../../../reducers/contants';

export const checkHasTopNav = (pathname, filter) => {
  const pathnameRegex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    `.*(\/{1})(${filter})(\/.+|\\?.+|[^\_.]*)$`,
  );
  return pathnameRegex.test(pathname);
};

/**
 * @description This orchestration hook that takes each feature
 * and loads it into a single object to be used by <StudyLayout />
 * @param {object} props
 * @returns
 */
export default function useStudyLayout({
  loadGraph,
  loadGraphPreview,
  graph,
  authorization,
  username,
  history,
  match,
  location,
  clearPublishErrors,
}) {
  const [majorVersion, setMajorVersion] = useState('');

  const {
    params: { graph_id },
  } = match;
  const { loading, load } = useLoad({
    loadGraph,
    authorization,
    history,
    graph_id,
  });
  const { checkCraUser, craModal, roleId, toggleCraModal } = useCra({
    authorization,
    username,
  });

  const { exportModal, toggleExportModal, importModal, toggleImportModal } =
    useExportImportLang();

  const { exportAuditTrailModal, toggleExportAuditTrailModal } =
    useExportAuditTrail();

  const {
    versions,
    versionHistoryModal,
    handleOnClickOutside,
    toggleVersionHistoryModal,
    canUserRevertPublishes,
    revertStudy,
    toggleRevertModal,
    enableRevertAndSetSelected,
    loading: versionHistoryLoading,
    revertModal,
    preview,
    previewLoading,
    clearPreview,
    loadPreview,
    selectedVersion,
  } = useVersionHistory({
    location,
    graph_id,
    authorization,
    clearPublishErrors,
    load,
    graph,
    loadGraphPreview,
  });

  const PathToStudy = `/study/${match.params.graph_id}`;
  const sidebarMenuOptions = generateMenuOptions(
    { history, authorization, graphId: graph_id },
    PathToStudy,
  );
  const isSlate = location?.pathname?.includes('slate');
  const isConfigure = location?.pathname?.includes('configure');
  const actions = authorization[match.params.graph_id]?.actions ?? [];
  const hasSideNav = !isConfigure;
  const hasTopNav = displaySecondaryMenu.some(
    (filter) =>
      checkHasTopNav(location?.pathname, filter) &&
      // never display top nav for urls containing settings
      !location?.pathname?.includes(doNotDisplaySecondaryMenu),
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (location.pathname.includes('studies')) {
      setMajorVersion('');
      searchParams.delete('major_version');
      return;
    }

    if (!searchParams.has('major_version') && majorVersion) {
      searchParams.append('major_version', majorVersion);
    }

    const majorVersionParam = searchParams.get('major_version');
    setMajorVersion(majorVersionParam);

    if (searchParams.toString() !== location.search?.substring(1)) {
      history.replace({ search: searchParams.toString() });
    }

    return () => {
      setMajorVersion('');
    };
  }, [location, history, majorVersion]);

  useEffect(() => {
    async function fetchData() {
      // hacky solution for the issue described at SPP-1109 and also to find the root cause
      if (graph_id && localStorage.getItem(userconst.token)) {
        await load(graph_id);
        await checkCraUser(graph_id);
      }
    }
    fetchData();
  }, [graph_id, checkCraUser, load]);

  return {
    checkPermissions,
    PathToStudy,
    sidebarMenuOptions,
    isSlate,
    isConfigure,
    actions,
    hasSideNav,
    hasTopNav,
    versionHistorySidebar: {
      handleOnClickOutside,
      canUserRevertPublishes,
      clearPreview,
      loadPreview,
      enableRevertAndSetSelected,
      revertStudy,
      preview,
      previewLoading,
      selectedVersion,
      versionHistoryModal,
      versions,
      toggleVersionHistoryModal,
      toggleRevertModal,
      revertModal,
    },
    loading: loading || versionHistoryLoading,
    cra: {
      craModal,
      toggleCraModal,
      roleId,
    },
    exportImportLang: {
      exportModal,
      toggleExportModal,
      importModal,
      toggleImportModal,
    },
    exportAuditTrail: {
      exportAuditTrailModal,
      toggleExportAuditTrailModal,
    },
  };
}
