import { useCallback, useEffect, useState } from 'react';

export default function useConnectionForm({ initialValues, onChange }) {
  const [connections, setConnections] = useState([]);

  useEffect(() => {
    // set the initial connections based on initial values
    // only if we dont have any connections in state.
    if (
      connections.length === 0 &&
      initialValues?.content?.available_apps?.length > 0
    ) {
      setConnections(
        initialValues.content.available_apps?.map((app) => ({
          connection: app,
        })),
      );
    } else if (
      connections.length === 0 &&
      initialValues?.content?.available_apps?.length === 0
    ) {
      setConnections([{}]);
    }
  }, [initialValues, connections, setConnections]);

  const handleRemoveConnection = useCallback(
    (idx) => {
      const mutable = [...connections];
      mutable.splice(idx, 1);
      setConnections(mutable);
      onChange(mutable);
    },
    [connections, setConnections, onChange],
  );

  const handleConnectionFormChange = useCallback(
    (id, values, ...args) => {
      const mutable = [...connections];
      mutable[id] = values;
      setConnections(mutable);
      onChange(mutable, ...args);
    },
    [connections, setConnections, onChange],
  );

  return {
    handleConnectionFormChange,
    connections,
    setConnections,
    handleRemoveConnection,
  };
}
