import { Button } from '@evidation/ui';
import { FC } from 'react';
import Modal from '../modal/SimpleModal';
import { useDeleteGraphLanguage } from './useTranslations';
import styled from 'styled-components';

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

const Description = styled.p`
  font-size: 1rem;
  font-family: Avenir, san-serif;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

interface DeleteLanguageModalProps {
  language: string;
  graphId: number;
  onClose: () => void;
}

const DeleteLanguageModal: FC<DeleteLanguageModalProps> = ({
  language,
  graphId,
  onClose,
}) => {
  const { mutate: onDeleteGraphLanguage } = useDeleteGraphLanguage();

  const onDeleteLanguage = () => {
    onDeleteGraphLanguage({
      graphId: graphId,
      localeId: language,
    });
  };

  return (
    <Modal showX modalTitle="Delete Language" onClose={onClose}>
      <ModalContainer>
        <Description>{language} will be permanently deleted.</Description>
        <Actions>
          <Button
            variant="secondary"
            size="large"
            type="button"
            data-testid="i18n-btn"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            size="large"
            type="button"
            onClick={onDeleteLanguage}
          >
            Delete Language
          </Button>
        </Actions>
      </ModalContainer>
    </Modal>
  );
};

export default DeleteLanguageModal;
