import { useState } from 'react';
import {
  selectedRemindersSelector,
  selectedSequencesSelector,
  selectedTilesSelector,
} from '../../../selectors/selectedResources';
import { Button } from '@evidation/form-elements';
import DuplicateReminder from './duplicateReminder';
import DuplicateTile from './duplicate_tile';
import DuplicateSequence from './duplicateSequence';
import { SelectionMenuContainer } from '.';
import { connect } from 'react-redux';
import onDeleteHOC from '../hoc/onDeleteHOC';
import { selectClearType } from '../../../actions/slate_actions';
import { setGraphSequencesTiles } from '../../../actions/combination';
import { withRouter } from 'react-router-dom';
import { AuthWrap } from '../../../components/auth';
import { AuthActions } from '../../../constants';
import Masonry from '../../../components/MasonryUtil';
import {
  DuplicateMasonryTile,
  DuplicateMasonrySequence,
  DuplicateMasonryReminder,
} from './slateButtons';
import Loader from 'src/components/Loader';

const text = {
  edit: 'Edit',
  editNew: 'Edit New',
  delete: 'Delete',
  clear: 'Clear',
  tilesSelected: 'tiles selected',
  remindersSelected: 'reminders selected',
  sequencesSelected: 'sequences selected',
};

const createTiles = [AuthActions.graphs.publish, AuthActions.tiles.create];
const updateTiles = [AuthActions.tiles.update];
const deleteTiles = [AuthActions.graphs.publish, AuthActions.tiles.destroy];
const updateSeq = [AuthActions.graphs.publish, AuthActions.sequences.update];
const deleteSeq = [AuthActions.graphs.publish, AuthActions.sequences.destroy];
const duplicateSeq = [
  AuthActions.graphs.publish,
  AuthActions.sequences.create,
  AuthActions.tiles.create,
];
const SELECTED_RESOURCES = 'selected_resources';

const SelectionMenu = (props) => {
  const {
    selected_tiles,
    selected_sequences,
    selected_reminders,
    history: { push },
    onDeleteMulti,
    selectClearType,
    match,
    graph: { masonry_enabled, id: graphId },
  } = props;

  const url = `/study/${match.params.graph_id}`;
  // Differentiate between Masonry and legacy components
  const configPath = masonry_enabled ? 'configure_new' : 'configure';
  const baseEditorUrl = `${url}/${configPath}`;
  const num_selected_tiles = selected_tiles.length;
  const num_selected_sequences = selected_sequences.length;
  const num_selected_reminders = selected_reminders.length;
  const showSelectionMenu =
    num_selected_tiles > 0 ||
    num_selected_sequences > 0 ||
    num_selected_reminders > 0;

  const [isLoading, setLoading] = useState(false);

  const showLoader = () => setLoading(true);
  const hideLoader = () => setLoading(false);

  const handleEditTile = () => {
    const { uuid, resource } = selected_tiles[0];
    push(
      {
        pathname: `${baseEditorUrl}/${resource}/${uuid}`,
        search: `?${SELECTED_RESOURCES}=${JSON.stringify(
          selected_tiles.map((tile) => tile.uuid),
        )}`,
      },
      {},
    );
  };

  const handleEditReminder = () => {
    const { reminder_uuid, resource } = selected_reminders[0];
    push(`${baseEditorUrl}/${resource}/${reminder_uuid}`, {});
  };

  const handleEditSequence = () => {
    const { sequence_uuid, resource } = selected_sequences[0];
    push(`${url}/edit/slate/${resource}/edit-new/${sequence_uuid}`, {});
  };

  return (
    <Loader show={isLoading}>
      {showSelectionMenu && (
        <SelectionMenuContainer id="t-selection-menu">
          {num_selected_tiles > 0 && (
            <div>
              <h3>
                {num_selected_tiles} {text.tilesSelected}
              </h3>
              <Button id="t-edit-tile" onClick={handleEditTile}>
                <AuthWrap requiredActions={updateTiles} elseDisplay={`View`}>
                  {text.edit}
                </AuthWrap>
              </Button>
              <AuthWrap requiredActions={deleteTiles}>
                <Button
                  id="t-delete-tile"
                  onClick={() => onDeleteMulti(selected_tiles)}
                >
                  {text.delete}
                </Button>
              </AuthWrap>

              <Button
                id="t-clear-tile-selection"
                onClick={() => selectClearType(`tile`)}
              >
                {text.clear}
              </Button>

              <AuthWrap requiredActions={createTiles}>
                {num_selected_tiles === 1 && (
                  <Masonry
                    renderDisabled={() => (
                      <DuplicateTile selected_tile={selected_tiles[0]} />
                    )}
                    renderEnabled={() => (
                      <DuplicateMasonryTile
                        selectedTile={selected_tiles[0]}
                        graphId={graphId}
                        toggleLoader={{ showLoader, hideLoader }}
                      />
                    )}
                  />
                )}
              </AuthWrap>
            </div>
          )}
          {num_selected_sequences > 0 && (
            <div>
              <h3>
                {num_selected_sequences} {text.sequencesSelected}
              </h3>
              {num_selected_sequences === 1 && (
                <Button id="t-edit-sequence" onClick={handleEditSequence}>
                  <AuthWrap requiredActions={updateSeq} elseDisplay={'View'}>
                    {text.edit}
                  </AuthWrap>
                </Button>
              )}

              <AuthWrap requiredActions={deleteSeq}>
                <Button
                  id="t-delete-sequence"
                  onClick={() => onDeleteMulti(selected_sequences)}
                >
                  {text.delete}
                </Button>
              </AuthWrap>

              <Button
                id="t-clear-sequence-selection"
                onClick={() => selectClearType(`sequence`)}
              >
                {text.clear}
              </Button>

              <AuthWrap requiredActions={duplicateSeq}>
                <Masonry
                  renderDisabled={() =>
                    num_selected_sequences > 0 ? (
                      <DuplicateSequence
                        selectedSequences={selected_sequences}
                        setGraphSequencesTiles={setGraphSequencesTiles}
                        {...props}
                      />
                    ) : null
                  }
                  renderEnabled={() =>
                    num_selected_sequences === 1 ? (
                      <DuplicateMasonrySequence
                        selectedSequence={selected_sequences[0]}
                        graphId={graphId}
                        toggleLoader={{ showLoader, hideLoader }}
                      />
                    ) : null
                  }
                />
              </AuthWrap>
            </div>
          )}
          {num_selected_reminders > 0 && (
            <div>
              <h3>
                {num_selected_reminders} {text.remindersSelected}
              </h3>

              <Button id="t-edit-reminders" onClick={handleEditReminder}>
                <AuthWrap requiredActions={updateTiles} elseDisplay={`View`}>
                  Edit
                </AuthWrap>
              </Button>

              <AuthWrap requiredActions={deleteTiles}>
                <Button
                  id="t-delete-reminder"
                  onClick={() => onDeleteMulti(selected_reminders)}
                >
                  Delete
                </Button>
              </AuthWrap>

              <Button
                id="t-clear-reminder-selection"
                onClick={() => selectClearType(`reminder`)}
              >
                Clear
              </Button>

              <AuthWrap requiredActions={createTiles}>
                {num_selected_reminders === 1 && (
                  <Masonry
                    renderDisabled={() => (
                      <DuplicateReminder
                        selected_reminder={selected_reminders[0]}
                      />
                    )}
                    renderEnabled={() => (
                      <DuplicateMasonryReminder
                        selectedReminder={selected_reminders[0]}
                        graphId={graphId}
                        toggleLoader={{ showLoader, hideLoader }}
                      />
                    )}
                  />
                )}
              </AuthWrap>
            </div>
          )}
        </SelectionMenuContainer>
      )}
    </Loader>
  );
};

export default connect(
  (state) => ({
    selected_tiles: selectedTilesSelector(state),
    selected_reminders: selectedRemindersSelector(state),
    selected_sequences: selectedSequencesSelector(state),
  }),
  {
    selectClearType,
    setGraphSequencesTiles,
  },
)(onDeleteHOC(withRouter(SelectionMenu)));
