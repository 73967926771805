export const defaultSingInValue = {
    connected_apps: [],
    email: '',
    messages: [],
    participant_auth_token: '',
    layout: '',
    nodes: [],
    phone_number: null,
    pinned_contributions: [],
    status: '',
    tags: [],
};
export const defaultNodeContent = {
    type: '',
    form: [],
    title: '',
    available_apps: [],
    section_count: undefined,
    section_index: undefined,
};
export const defaultNodeValue = {
    slug: '',
    type: '',
    content: defaultNodeContent,
    status: '',
    page: 0,
    total: 0,
};
export const metaDefaultValues = {
    slug: '',
    study_name: '',
    study_summary: '',
    status: '',
    primary_locale: '',
    authentication: {
        provider: '',
        details: undefined,
        display_expiration_modal: true,
    },
    locale: '',
    i18n_enabled: false,
    userAgent: {
        browser: {
            name: '',
            version: 0,
            isMobile: false,
        },
        screenRes: {
            width: 0,
            height: 0,
        },
        timeZone: '',
        os: {
            name: '',
            version: '',
        },
        device: {
            vendor: undefined,
            name: undefined,
        },
    },
    visitorId: '',
    dashboard_header: '',
    display_groups: [],
    default_display_group_name: '',
    completed_display_group_name: '',
    study_completion_message: '',
    reconsent_modal_body: '',
    reconsent_modal_title: '',
};
