import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  type GraphLocale,
  translationsService,
  UploadedLocale,
} from '../../api/translations';
import { toastr } from 'react-redux-toastr';
import { type Study } from 'src/redux/graph';
import { isAxiosError } from 'axios';
import { downloadFileUrl } from 'src/utils/urlHelpers';
import { transformToCSV } from './utils';

export const useGetGraphLocales = (graphId: number) => {
  const { data, ...rest } = useQuery<GraphLocale[]>(
    ['graph-locales', graphId],
    () => translationsService.getAll(graphId),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  );
  return {
    data,
    ...rest,
  };
};

export const useCreateGraphLanguage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: translationsService.create,
    onSuccess: (data: GraphLocale) => {
      toastr.success('Success', `Language added: "${data.name}"`);
    },
    onError: (error) => {
      if (!isAxiosError(error)) return;
      toastr.error(
        'Error',
        `Failed to add language, "${error.response?.data?.error}"`,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['graph-locales']);
    },
  });
};

export const useUpdateGraphDefaultLanguage = () => {
  return useMutation({
    mutationFn: translationsService.updateDefaultLocale,
    onSuccess: (data: Study) => {
      toastr.success(
        'Success',
        `Default language successfully updated to: "${data.primary_locale}"`,
      );
    },
    onError: (error) => {
      if (!isAxiosError(error)) return;
      toastr.error(
        'Error',
        `Failed to update default language, "${error.response?.data?.error}"`,
      );
    },
  });
};

export const useDeleteGraphLanguage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: translationsService.deleteLocale,
    onSuccess: () => {
      toastr.success('Success', 'Language successfully deleted.');
    },
    onError: (error) => {
      if (!isAxiosError(error)) return;
      toastr.error(
        'Error',
        `Failed to delete language, "${error.response?.data?.error}"`,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['graph-locales']);
    },
  });
};

export const useDowloadGraphLanguage = () => {
  return useMutation({
    mutationFn: translationsService.getLocaleTranslations,
    onSuccess: (
      data: UploadedLocale[],
      variables: { graphId: number; locale: string },
    ) => {
      const dataFormatted = transformToCSV(data, variables.locale);
      const utf8BOM = '\ufeff';
      const blob = new Blob([utf8BOM + dataFormatted], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      downloadFileUrl(url);
      toastr.success('Success', 'Your file has been downloaded.');
    },
    onError: (error) => {
      if (!isAxiosError(error)) return;
      toastr.error(
        'Error',
        `Failed to download the file, "${error.response?.data?.error}"`,
      );
    },
  });
};

export const useUploadGraphLanguages = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: translationsService.uploadLocales,
    onSuccess: () => {
      toastr.success('Success', 'Languages successfully uploaded.');
    },
    onError: (error) => {
      if (!isAxiosError(error)) return;
      toastr.error(
        'Error',
        `Failed to upload languages, "${error.response?.data?.error}"`,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['graph-locales']);
    },
  });
};
