"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stripHtml = exports.sanitizeHTML = exports.isEmpty = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.string.trim.js");
var _sanitizeHtml = _interopRequireDefault(require("sanitize-html"));
var sanitizeHTML = exports.sanitizeHTML = function sanitizeHTML(dirtyHtmlString) {
  return dirtyHtmlString ? (0, _sanitizeHtml["default"])(dirtyHtmlString) : '';
};
var stripHtml = exports.stripHtml = function stripHtml() {
  var _doc$body$textContent;
  var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var sanitizedInput = (string === null || string === void 0 ? void 0 : string.replace(/<+/g, '<').replace(/>+/g, '>')) || '';
  var parser = new DOMParser();
  var doc = parser.parseFromString(sanitizedInput, 'text/html');
  return ((_doc$body$textContent = doc.body.textContent) === null || _doc$body$textContent === void 0 ? void 0 : _doc$body$textContent.replace(/\u00A0/g, ' ').trim()) || '';
};
var isEmpty = exports.isEmpty = function isEmpty(value) {
  return value === '' || value === undefined || value === null;
};