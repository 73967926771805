import { FC } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Select, Button } from '@evidation/ui';
import { useUpdateGraphDefaultLanguage } from './useTranslations';
import { type Study } from 'src/redux/graph';
import { type Option } from '@evidation/ui/lib/Select';

type TranslationsForm = {
  language: string;
};

interface DefaultLocaleFormProps {
  graph: Study;
  languageOptions: Option[];
}

const Form = styled.form`
  display: flex;
  gap: 1rem;

  > div {
    margin-bottom: 0;
  }
`;

const FormButton = styled(Button)`
  height: 40px;
  align-self: end;
`;

const DefaultLanguageForm: FC<DefaultLocaleFormProps> = ({
  graph,
  languageOptions,
}) => {
  const { mutate: updateDefaultLocale } = useUpdateGraphDefaultLanguage();

  const {
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<TranslationsForm>({
    defaultValues: {
      language: graph.primary_locale,
    },
  });

  const onSubmit = (data: TranslationsForm) => {
    updateDefaultLocale({
      graphId: `${graph.id}`,
      data: { name: data.language },
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Select
        error={errors.language ? 'This field is required' : undefined}
        label="Choose default language"
        options={languageOptions}
        onChange={(selected) =>
          setValue('language', selected.value, {
            shouldValidate: true,
          })
        }
        placeholder="Select a language"
        value={watch('language')}
      />
      <FormButton
        variant="primary"
        size="large"
        type="submit"
        data-testid="i18n-btn"
      >
        Update Language
      </FormButton>
    </Form>
  );
};

export default DefaultLanguageForm;
